import * as React from "react"

/** Returns React children with primitive nodes wrapped in a `<span data-text>`. */
export const toWrappedChildren = (children: React.ReactNode) =>
  React.Children.toArray(children).map((child, key) =>
    React.isValidElement(child)
      ? child
      : React.createElement(
          "span",
          {
            "data-text": "",
            key,
          },
          child,
        ),
  )

/** Returns React props with primitive children nodes wrapped in a `<span data-text>`. */
export const withWrappedChildren = <T extends object>(props: T): T => {
  if ("children" in props) {
    props.children = toWrappedChildren(props.children as any)
  }

  return props
}

/** Returns React props with primitive children nodes wrapped in a `<span data-text>`. */
export const withClass = <
  T extends object & { className?: unknown },
  C extends (props: object) => string,
>(
  { ...props }: T,
  classMethod: C,
  classProps?: C extends (props: infer R) => string
    ? R extends object
      ? Omit<R, "className">
      : undefined
    : undefined,
): T => {
  props.className = classMethod({
    className: props.className,
    ...classProps,
  })

  return props
}

export const mapChildren = <T extends React.ReactNode, R>(
  children: T,
  callback: (child: IterableOf<T>, index: number, array: IterableOf<T>[]) => R,
): R[] => [children].flat().map(callback)

export type IterableOf<T> = T extends readonly (infer R)[] ? R : T
